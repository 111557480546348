// import { onMounted } from 'vue'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { useAppStore } from '@/stores/app'
import { logError } from '@/utils/error-logger'
import useDeviceAndBrowser from './useDeviceAndBrowser'


const appVersion = import.meta.env?.VITE_APP_VERSION || '';
const SW_UPDATE_KEY = 'sw-update-in-progress';


export function useUpdateNotification() {
  const appStore = useAppStore()

  const intervalMS = import.meta.env?.VITE_CHECK_FRESH_VERSION_INTERVAL || 60 * 60 * 1000
  const { userAgent } = useDeviceAndBrowser()

  const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
    // immediate: true,
    onRegisteredSW(swUrl, r) {
      if (r) {
        console.log('[SW] Initial registration', {
          url: swUrl,
          isProd: import.meta.env?.PROD,
          checkInterval: intervalMS,
          registration: r
        })

        // send the registration to the app store
        appStore.setRegistration(r)

        setInterval(async () => {
          if (r.installing || !navigator) {
            console.log('[SW] Skip update check - worker installing or no navigator')
            return
          }

          if ('connection' in navigator && !navigator.onLine) {
            console.log('[SW] Skip update check - offline')
            return
          }

          try {
            const resp = await fetch(swUrl, {
              cache: 'no-store',
              headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
              }
            })

            if (resp?.status === 200) {
              console.log('[SW] Checking for updates...')
              await r.update()
              // Add check to prevent multiple updates
              if (r.waiting) {
                console.log('[SW] Update available but waiting')
              }
            }
          } catch (error) {
            console.error('[SW] Error checking for updates:', error)
          }
        }, intervalMS)
      }
    },
    onNeedRefresh() {
      console.log('[SW] onNeedRefresh called (PROD value=', import.meta.env?.PROD, ')')

      // Check if we just reloaded from an update
      if (localStorage.getItem(SW_UPDATE_KEY) === 'true') {
        console.log('[SW] Ignoring update notification after reload')
        localStorage.removeItem(SW_UPDATE_KEY)
        return
      }

      // Add debounce to prevent rapid updates
      if (import.meta.env?.PROD && !appStore.updateAvailable) {
        console.log('[SW] Setting update available')
        appStore.updateAvailable = true
      }
    }
  })
  const refreshApp = async () => {
    const loc = window.location?.pathname || 'unknown page'

    // Set flag to indicate we're processing an update
    localStorage.setItem(SW_UPDATE_KEY, 'true')

    // Wait for the log to be sent to the backend
    try {
      // This is async/await to be sure the error is sent before the reload
      await logError('New content popup clicked', loc, {
        currentVersion: appVersion,
        userAgent: userAgent
      })
      console.log('Update notification log successfully sent to backend')
    } catch (error) {
      console.error('Failed to send update notification log to backend:', error)
    }

    // First update the service worker. This will skip the waiting state
    // and activate the new service worker immediately
    await updateServiceWorker(true)
    appStore.updateAvailable = false

    console.log("Waiting for 2 seconds before clearing caches and reloading...")
    await new Promise(resolve => setTimeout(resolve, 2000))

    // Force cache clearing and page reload
    if ('caches' in window) {
      try {
        const cacheNames = await caches.keys()
        await Promise.all(
          cacheNames.map(cacheName => caches.delete(cacheName))
        )
        console.log('All caches cleared successfully')
      } catch (error) {
        console.error('Error clearing caches:', error)
      }
    }

    window.location.reload()
  }

  const cancel = () => {
    appStore.updateAvailable = false
    offlineReady.value = false
    needRefresh.value = false
  }

  return {
    offlineReady,
    needRefresh,
    cancel,
    refreshApp
  }
}
