import { ref, onMounted, onUnmounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useWindowSize } from '@vueuse/core'
import consultationDataService from '@/services/consultationDataService'
import { ISystemStatusNote } from '@/types'
import { logError } from '@/utils/error-logger'
import {
  InformationCircleIcon,
  ExclamationTriangleIcon,
  ExclamationCircleIcon
} from '@heroicons/vue/24/solid'

export const useSystemNote = () => {
  const { width } = useWindowSize()
  const { locale } = useI18n()
  const statusData = ref<ISystemStatusNote>()
  const timerId = ref()
  // 5 mins
  const broadcastInterval = 5 * 60 * 1000

  const notificationIcon = computed(() => {
    switch (statusData.value?.type) {
      case 'info':
        return InformationCircleIcon
      case 'warning':
        return ExclamationTriangleIcon
      case 'alert':
        return ExclamationCircleIcon
    }
  })
  const isVisible = computed(() => statusData.value?.toDisplay)
  const message = computed(
    () => statusData.value?.message && statusData.value.message[locale.value as 'en' | 'de']
  )
  const colorClasses = computed(() => {
    switch (statusData.value?.type) {
      case 'info':
        return 'text-blue-500 bg-blue-500/10'
      case 'warning':
        return 'border-[#967032] bg-[#F8F3D6] text-[#967032]'
      case 'alert':
        return 'text-red-500 bg-red-500/10'
    }
  })

  const getStatusNote = async () => {
    try {
      const { data } = await consultationDataService.getStatusNotification()
      statusData.value = data
    } catch (error: any) {
      logError(
        'Error with getting system status note',
        'useSystemNote.ts:getStatusNote',
        error?.response?.data || error?.response || error
      )
    }
  }

  onMounted(() => {
    // To not setup interval on mobile. Tailwind config lg = 1024px
    if (width.value > 1024) {
      getStatusNote()
      timerId.value = setInterval(() => {
        getStatusNote()
      }, broadcastInterval)
    }
  })

  onUnmounted(() => {
    if (timerId.value) {
      clearInterval(timerId.value)
    }
  })

  return {
    isVisible,
    message,
    colorClasses,
    notificationIcon
  }
}
