<template>
  <div class="relative" :class="{ 'pb-8': props.limit }">
    <textarea
      ref="textareaRef"
      :id="props.id"
      :value="props.modelValue"
      data-testid="summary-textarea"
      class="block h-auto w-full resize-none overflow-auto rounded-md p-3.5 text-black outline-none ring-1 ring-gray-200 transition-all focus:ring-accent-500"
      @input="handleInput"
      v-bind="$attrs"
    />

    <div v-if="props.limit" class="absolute bottom-0 right-0 text-gray-400">
      {{ feedbackLength }}/{{ props.limit }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, nextTick, computed } from 'vue'
import { useWindowSize, watchDebounced } from '@vueuse/core'

interface Props {
  id?: string
  modelValue?: string
  limit?: number
}
const props = withDefaults(defineProps<Props>(), { modelValue: '' })
const emit = defineEmits<{
  'update:model-value': [string | undefined]
}>()

const { width } = useWindowSize()
const textareaRef = ref()
const feedbackLength = computed(() => (props.modelValue || '').length)

function adjustHeight(el?: HTMLTextAreaElement) {
  if (!el) return

  el.style.height = 'auto'
  el.style.height = el.scrollHeight + 'px'
}
function handleInput(e: any) {
  if (props.limit && (e?.target?.value || '').length > props.limit) {
    const value = (e.target?.value || '').slice(0, 500)
    emit('update:model-value', value)
    e.target.value = value || ''
    return
  }

  adjustHeight(e.target as HTMLTextAreaElement)
  emit('update:model-value', e?.target?.value || '')
}

watch(
  () => props.modelValue,
  () => {
    nextTick(() => adjustHeight(textareaRef.value))
  },
  { immediate: true }
)
watchDebounced(
  () => width.value,
  () => {
    console.log('watchDebounced')
    nextTick(() => adjustHeight(textareaRef.value))
  },
  { debounce: 200, maxWait: 1000 }
)

onMounted(() => {
  adjustHeight(textareaRef.value)
})

defineExpose({ textareaRef })
</script>
