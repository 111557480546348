import { defineStore } from 'pinia'
import { ref } from 'vue'
import AppDataService from '@/services/appDataService'

export const useAppStore = defineStore('app', () => {
  const updateAvailable = ref(false)
  const registration = ref<ServiceWorkerRegistration | undefined>()
  const lastUpdateCheck = ref<number>(0)
  const MIN_UPDATE_INTERVAL = 5 * 60 * 1000 // 5 minutes

  const setRegistration = (reg: ServiceWorkerRegistration) => {
    registration.value = reg
    console.log('[APP STORE] Registration set from useRegisterSW', reg)
  }

  /* const initSWRegistration = async () => {
    if ('serviceWorker' in navigator) {
      const reg = await navigator.serviceWorker.getRegistration().catch((error) => {
        console.log('[APP STORE] initSWRegistration error:', error)
      })

      if (reg) {
        console.log(
          '[APP STORE] Existing service worker registration found',
          reg
        )
        registration.value = reg
      }

      return registration.value
    }
  } */

  const fetchLatestVersion = async () => {
    try {
      // Fetch from your backend API with cache busting
      const response = await AppDataService.getAppVersions()
      const { webapp: latestVersion } = response.data
      return latestVersion
    } catch (error) {
      console.error('[APP STORE] Error fetching latest version:', error)
      return null
    }
  }

  const immediateCheckForUpdates = async () => {
    try {
      console.log('Running update check after reload')
      console.log("lastUpdateCheck.value: ", lastUpdateCheck.value)

      // Prevent frequent checks
      const now = Date.now()
      if (now - lastUpdateCheck.value < MIN_UPDATE_INTERVAL) {
        console.log('[APP STORE] Skipping update check - too soon')
        return false
      }

      lastUpdateCheck.value = now;

      if (!registration.value) {
        console.log('[APP STORE] No registration found')
        return false
      }

      console.log('[APP STORE] Checking for updates')
      await registration.value.update()

      console.log('[APP STORE] Fetching latest version')
      const latestVersion = await fetchLatestVersion()
      const currentVersion = import.meta.env?.VITE_APP_VERSION || ''
      console.log('[APP STORE] Current version:', currentVersion)
      console.log('[APP STORE] Latest version from API:', latestVersion)

      // check also the persisted value in local storage
      const hasUpdate = latestVersion && latestVersion !== currentVersion

      // OLD WAY:
      // const hasUpdate = !!registration.value.waiting  // (not working because it's done async and waiting value might not be set when we compare it)

      console.log('[APP STORE] Update available:', hasUpdate)
      updateAvailable.value = hasUpdate

      return hasUpdate
    } catch (error) {
      console.error('[APP STORE] Error checking for updates:', error)
      return false
    }
  }

  const forceCheckForUpdates = async () => {
    // Skip the time check for critical operations
    console.log('[APP STORE] Force checking for updates')
    lastUpdateCheck.value = 0
    const resp = await immediateCheckForUpdates()
    return resp;
  }

  return {
    updateAvailable,
    registration,
    lastUpdateCheck,
    immediateCheckForUpdates,
    forceCheckForUpdates,
    setRegistration
  }
})
