<template>
  <select id="language-selector" :value="locale" @change="handleSetLocale" data-testid="language-selector">
    <option v-for="locale in availableLocales" :key="locale" :value="locale" :data-testid="`language-option-${locale}`"
      class="uppercase">
      {{ locale }}
    </option>
  </select>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useUserDataStore } from '@/stores/user'
import userDataService from '@/services/userDataService'

const props = withDefaults(
  defineProps<{
    withApiRequest?: boolean
  }>(),
  {
    withApiRequest: true
  }
)

const userStore = useUserDataStore()

const { locale, availableLocales } = useI18n({ useScope: 'global' })

const handleSetLocale = (event: any) => {
  userStore.changeLocale(event.target.value)

  if (props.withApiRequest) {
    userDataService.changeLocale(event.target.value)
  }
}
</script>
