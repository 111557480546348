<template>
  <ErrorCatcher>
    <ReloadPrompt />
    <Toaster position="top-center" />

    <component :is="$route.meta.layoutComponent">
      <RouterView />
    </component>
  </ErrorCatcher>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router'
import { onMounted, ref, watch, nextTick, onUnmounted } from 'vue'
import { Toaster } from 'vue-sonner'
import { useWakeLock, useOnline } from '@vueuse/core'
import ReloadPrompt from '@/components/ReloadPrompt.vue'
import ErrorCatcher from '@/components/ErrorCatcher.vue'
import { toaster } from '@/utils/toaster'
import { useI18n } from 'vue-i18n'
import useCookieLocale from '@/composables/useCookieLocale'
import { useUserDataStore } from '@/stores/user'
import * as Sentry from '@sentry/vue'
import { logError } from './utils/error-logger'
import { Network } from '@capacitor/network'
import { Capacitor } from '@capacitor/core'

const route = useRoute()
const { isSupported } = useWakeLock()
const { t, locale } = useI18n({ useScope: 'global' })
const { currentLang } = useCookieLocale()
const userStore = useUserDataStore()
const online = useOnline()
const isMobileOnline = ref()

const startTimeOffline = ref<number>(0)

const toasterID = ref<number | string | null>(null)

function isOnlineHandler(newIsOnline: boolean, oldIsOnline: boolean) {
  isMobileOnline.value = newIsOnline
  // Show "You are offline" tip
  nextTick(() => {
    console.log('watch is Online', newIsOnline)
    if (!newIsOnline) {
      toasterID.value = toaster.error(t('generalTips.offline'), {
        dismissible: false,
        closeButton: false,
        duration: Infinity
      })
    } else {
      toaster.dismiss()
    }
  })

  // Skip it on first page load
  if (typeof oldIsOnline === 'undefined' || typeof newIsOnline === 'undefined') {
    return
  }

  if (!newIsOnline && oldIsOnline) {
    // Go offline
    startTimeOffline.value = new Date().getTime()
  }

  // Go online
  if (!oldIsOnline && newIsOnline) {
    // Elapsed seconds
    const _elapsedTime = startTimeOffline.value
      ? Math.round((new Date().getTime() - startTimeOffline.value) / 1000)
      : 0

    if (_elapsedTime > 0) {
      logError('User was offline', 'App.vue => watch', {
        offlineDurationInSeconds: _elapsedTime
      })
    }

    startTimeOffline.value = 0
  }
}
// @ts-ignore
watch(online, isOnlineHandler, { immediate: true })
watch(
  () => route.query,
  (newQuery: any) => {
    if (newQuery?.lang) {
      locale.value = (Array.isArray(newQuery?.lang) ? newQuery.lang[0] : newQuery.lang) as string
      userStore.changeLocale(locale.value)
      return
    }
  }
)

onMounted(async () => {
  if (Capacitor.isNativePlatform()) {
    Network.addListener('networkStatusChange', async (status: any) => {
      isOnlineHandler(status.connected, isMobileOnline.value)
    })
  }

  if (userStore.userData?.user?.username) {
    Sentry.setUser({
      email: userStore.userData?.user?.username,
      username: userStore.userData?.user?.username
    })
  }

  if (!isSupported.value) {
    toaster.warn(t('wakeLock.notSupported'))
    return
  }

  // console.log('onMounted', userStore.userData?.locale)
  if (userStore.userData?.locale || currentLang.value) {
    locale.value = (userStore.userData?.locale || currentLang.value) as string
    userStore.changeLocale(locale.value)
  } else {
    currentLang.value = 'de'
    locale.value = 'de'
  }
})

onUnmounted(() => {
  if (Capacitor.isNativePlatform()) {
    Network.removeAllListeners()
  }
})
</script>
