import consultationDataService from '@/services/consultationDataService'
import { useUserDataStore } from '@/stores/user'
import { useConsultationsStore } from '@/stores/consultations'
import { WSEvent } from '@/types'
import { logError } from '@/utils/error-logger'
import { useWebSocket, useOnline } from '@vueuse/core'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { toaster } from '@/utils/toaster'
import { useI18n } from 'vue-i18n'

export default function useConsultationsWS() {
  const { t } = useI18n()
  const { userData } = useUserDataStore()
  const consultationsStore = useConsultationsStore()
  const online = useOnline()

  // Simple state tracking (in milliseconds)
  const lastVisibilityState = ref<string>(document.visibilityState)
  const lastVisibilityTime = ref<number>(Date.now())
  const lastOnlineState = ref<boolean>(navigator.onLine)

  // Sleep detection threshold (60 seconds)
  const SLEEP_THRESHOLD = 60

  const wsURL = computed(() => `${import.meta.env?.VITE_WS_URL}${userData.user?._id}`)

  const { open, close, data, status } = useWebSocket(wsURL, {
    onMessage: async (_: WebSocket, event: MessageEvent<string>) => {
      const wsData: WSEvent = event.data ? JSON.parse(event.data) : null
      console.log('event: ', wsData, wsData?.event)

      switch (wsData?.event) {
        case 'deleted':
          consultationsStore.handleExcludeConsultation(wsData.consultationId)
          break

        case 'created':
          try {
            consultationsStore.aiProcessingConsultation = true
            consultationsStore.consolidatingConsultation = false

            await _updateConsultationDetailsInList(wsData.consultationId)
          } catch (error: any) {
            console.log(error)
            logError(
              "Error: user can't get consultation details, after WS ping it's created",
              'view/composables/useConsultations:onMessage()',
              error?.response?.data || error?.response || error
            )
          }
          break

        case 'ready':
          try {
            // consultationsStore.loadingConsultation = true
            consultationsStore.consolidatingConsultation = false
            consultationsStore.aiProcessingConsultation = false

            console.log('Ready but not updated')
            await _updateConsultationDetailsInList(wsData.consultationId)
            console.log('Ready and updated')
          } catch (error: any) {
            console.log(error)
            logError(
              "Error: user can't get consultation details, after WS ping it's ready",
              'view/composables/useConsultations:onMessage()',
              error?.response?.data || error?.response || error
            )
          } finally {
            consultationsStore.loadingConsultation = false
          }
          break

        default:
          break
      }
    },
    autoReconnect: {
      retries: 15,
      delay: 1000,
      onFailed: () => {
        consultationsStore.consolidatingConsultation = false
        consultationsStore.aiProcessingConsultation = false

        if (online.value) {
          toaster.error(t('consultationIndexView.waitingScreens.toaster.wsConnectionFailed'))
          logError(
            t('consultationIndexView.waitingScreens.toaster.wsConnectionFailed'),
            'view/composables/useConsultations:onFailed()',
            {}
          )
        }
      }
    }
  })

  const _updateConsultationDetailsInList = async (consultationId: string) => {
    try {
      const { data } = await consultationDataService.getConsultationById(consultationId)
      const isAlreadyExist = !!consultationsStore.consultationList.find(
        (item) => item._id === data._id
      )

      if (isAlreadyExist) {
        consultationsStore.handleUpdateConsultation(data)
      } else {
        consultationsStore.handleAddConsultation(data)
      }

      if (
        consultationsStore.selectedConsultationId &&
        !consultationsStore.isCreatingNewConsultation
      ) {
        consultationsStore.selectConsultation(consultationsStore.selectedConsultationId)
      }
    } catch (error: any) {
      console.log(error)
      logError(
        "Error: user can't get consultation details, after WS ping it's ready",
        'view/composables/useConsultations:onMessage()',
        error?.response?.data || error?.response || error
      )
    }
  }

  const _handleVisibilityChange = async () => {
    const currentTime = Date.now()
    const timeSinceLastChange = (currentTime - lastVisibilityTime.value) / 1000
    const currentVisibilityState = document.visibilityState

    // Only log if there's an actual change in visibility state
    if (currentVisibilityState !== lastVisibilityState.value) {
      if (currentVisibilityState === 'visible') {
        console.log('App became visible')

        // If we're recording, log the return to visibility
        if (consultationsStore.recordingConsultationId) {
          // Check if this was likely a wake from sleep mode
          if (timeSinceLastChange > SLEEP_THRESHOLD) {
            logError(
              'Device likely woke from sleep mode',
              'useConsultationWS:visibilityChange',
              {
                consultationId: consultationsStore.recordingConsultationId,
                timeSinceLastChange,
                isOnline: navigator.onLine
              }
            )
          }
        }

        // Refresh consultation list
        consultationsStore.consultationList = await consultationsStore.getConsultationList({
          page: 1,
          size: (consultationsStore.pagination?.page || 1) * consultationsStore.pagination.size
        })

        if (
          consultationsStore.selectedConsultationId &&
          !consultationsStore.isCreatingNewConsultation
        ) {
          consultationsStore.selectConsultation(consultationsStore.selectedConsultationId)
        }

        if (status.value === 'CLOSED') {
          open()
        }
      } else if (currentVisibilityState === 'hidden') {
        //console.log('App became hidden')

        // Only log if we're recording
        /*if (consultationsStore.recordingConsultationId) {
          // Determine the most likely reason
          let reason = 'Unknown reason'

          if (!document.hasFocus()) {
            reason = 'App minimized, tab switched, or screen locked'
          } else if (!navigator.onLine) {
            reason = 'Screen locked (offline)'
          } else {
            reason = 'Screen likely locked'
          }

          logError(
            `App visibility lost`,
            'useConsultationWS:visibilityChange',
            {
              consultationId: consultationsStore.recordingConsultationId,
              reason
            }
          )
        }*/
      }

      // Update state tracking
      lastVisibilityState.value = currentVisibilityState
      lastVisibilityTime.value = currentTime
    }
  }

  // Handle network state changes
  const _handleNetworkChange = () => {
    const currentOnlineState = navigator.onLine

    // Only process if there's an actual change in network state
    if (currentOnlineState !== lastOnlineState.value) {
      console.log(`Network state changed: ${currentOnlineState ? 'online' : 'offline'}`)

      // Only log if we're recording
      if (consultationsStore.recordingConsultationId) {
        logError(
          currentOnlineState ? 'Network connection restored' : 'Network connection lost',
          'useConsultationWS:networkChange',
          {
            consultationId: consultationsStore.recordingConsultationId,
            visibilityState: document.visibilityState,
            previousNetworkState: lastOnlineState.value
          }
        )
      }

      // Update state tracking
      lastOnlineState.value = currentOnlineState
    }
  }

  onMounted(() => {
    document.addEventListener('visibilitychange', _handleVisibilityChange)
    window.addEventListener('online', _handleNetworkChange)
    window.addEventListener('offline', _handleNetworkChange)
  })

  onBeforeUnmount(() => {
    document.removeEventListener('visibilitychange', _handleVisibilityChange)
    window.removeEventListener('online', _handleNetworkChange)
    window.removeEventListener('offline', _handleNetworkChange)
  })

  return {
    wsURL,
    open,
    close,
    data,
    status
  }
}
