<template>
  <!-- NewFeatureDialog shows only once on new release -->
  <NewFeaturePopup
    v-model:visible="newFeatureDialogVisible"
    :markdown="newFeatureDialogContent"
    :image="newFeatureDialogImage"
    @close="sendElapsedTime(`pressed on the popup overlay`)"
    @cancel="sendElapsedTime(`pressed on 'cancel'`)"
    @ok="sendElapsedTime(`pressed on 'try it now'`)"
  />

  <!-- BrowserWarning shows only one time -->
  <BaseDialog
    type="info"
    :title="$t('dialogs.useChromeTips.title')"
    :content="$t('dialogs.useChromeTips.content')"
    :visible="browserRecommendationPopupVisible"
    @update:visible="hideBrowserRecommendationPopup"
  >
    <template #actions>
      <button
        type="button"
        class="flex items-center justify-center gap-3 rounded-md border border-accent-500 bg-accent-500/10 px-4 py-2 text-xl font-semibold text-accent-500 shadow-sm hover:bg-accent-500/30 sm:ml-3 sm:w-auto xl:text-base"
        @click="hideBrowserRecommendationPopup"
      >
        {{ $t('dialogs.useChromeTips.actions.understand') }}
      </button>
    </template>
  </BaseDialog>

  <div class="h-[100dvh] flex flex-col">
    <div v-if="isSystemNoteVisible" class="w-full px-5 pt-3 max-lg:hidden">
      <div
        class="flex h-10 items-center justify-center gap-4 rounded-lg text-center font-medium"
        :class="colorClasses"
      >
        <component :is="notificationIcon" class="size-6 shrink-0" /> {{ message }}
      </div>
    </div>

    <div
      class="flex flex-1 items-stretch gap-x-3.5 overflow-y-auto bg-white max-lg:flex-col lg:px-5 lg:py-6"
      :class="{ 'lg:pt-3': isSystemNoteVisible }"
    >
      <div class="flex shrink-0 flex-col lg:h-full lg:w-[340px] lg:gap-6">
        <header class="top-0 z-[20] border-gray-200 bg-white max-lg:sticky max-lg:border-b">
          <div
            class="relative flex shrink-0 items-center justify-between gap-6 rounded-lg bg-[#E5F2EE] px-8 py-6 max-lg:mx-4 max-lg:my-2.5 overflow-hidden"
          >
            <TheLogo class="max-h-[30px] text-black shrink-0" />

            <div class="flex items-center gap-3 min-w-0 max-w-[60%] z-[200]">
              <UserInfo class="flex-row-reverse lg:!hidden w-full" />
            </div>
          </div>

          <div
            class="mt-[30px] flex gap-2 text-lg font-medium leading-5 text-gray-500 max-lg:px-4 max-lg:py-2.5 lg:hidden"
          >
            <button
              id="newConsultationTabBtn"
              type="button"
              class="flex grow items-center justify-center gap-2 space-x-2 rounded-[10px] px-4 py-6 hover:bg-gray-300 hover:text-black xs:basis-1/2"
              :class="{ 'bg-[#F2F2F2] text-black': consultationsStore.isCreatingNewConsultation }"
              @click="consultationsStore.handleCreateNewConsultation"
            >
              <DocumentTextIcon class="h-6 w-6" />
              {{ $t('consultationIndexView.actions.newConsultation') }}
            </button>

            <button
              id="notesTabBtn"
              type="button"
              class="flex grow items-center justify-center gap-2 space-x-2 rounded-[10px] px-4 py-6 hover:bg-gray-300 hover:text-black xs:basis-1/2"
              :class="{ 'bg-[#F2F2F2] text-black': !consultationsStore.isCreatingNewConsultation }"
              @click="consultationsStore.handleShowListOfNotes"
            >
              <PencilSquareIcon class="h-6 w-6" />
              {{ $t('consultationIndexView.actions.notes') }}
            </button>
          </div>
        </header>

        <aside
          class="flex grow flex-col overflow-hidden border-gray-200 lg:rounded-lg lg:border lg:bg-[#F6F6F6]"
          :class="{
            'max-lg:hidden':
              consultationsStore.isCreatingNewConsultation ||
              !!consultationsStore.selectedConsultationId
          }"
        >
          <div class="z-10 bg-[#F6F6F6] p-8 text-center max-lg:hidden">
            <BaseButton
              class="!bg-accent-500"
              @click="consultationsStore.handleCreateNewConsultation"
            >
              <PlusIcon class="h-6 w-6" />
              {{ $t('consultationIndexView.actions.newVisit') }}
            </BaseButton>
          </div>

          <ConsultationsList />

          <div class="shrink-0 border-t border-gray-200 bg-[#F6F6F6] py-4 px-8">
            <div class="mb-3">
              <a href="https://playnvoice.notion.site/playnvoice-help-center"
                target="_blank" rel="noopener noreferrer"
                class="inline-flex items-center gap-2 text-gray-600 hover:text-accent-600 transition-colors"
                data-testid="help-center-link">
                <QuestionMarkCircleIcon class="h-5 w-5" aria-hidden="true" />
                <span class="font-medium underline underline-offset-2">{{ $t('generalTips.helpCenter') }}</span>
              </a>
            </div>

            <UserInfo />
          </div>
        </aside>
      </div>

      <ConsultationDetails
        v-if="consultationsStore.selectedConsultationId && consultationsStore.selectedConsultation"
        class="h-full grow max-lg:w-full"
      />

      <div
        class="grow overflow-y-auto border-gray-200 max-lg:w-full lg:rounded-lg lg:border"
        :class="{
          'max-lg:hidden': !consultationsStore.isCreatingNewConsultation,
          hidden: !!consultationsStore.selectedConsultationId
        }"
      >
        <div
          class="sticky top-0 flex items-center gap-3 border-b border-gray-200 bg-white px-8 py-4 text-gray-500 max-lg:hidden"
        >
          <DocumentTextIcon class="h-6 w-6" />
          {{ $t('consultationIndexView.actions.newConsultation') }}
        </div>

        <RecordConsultation />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import { DocumentTextIcon, PlusIcon, PencilSquareIcon, QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'
import TheLogo from '@components/icons/TheLogo.vue'
import BaseButton from '@components/base/BaseButton.vue'
import ConsultationDetails from '@components/ConsultationDetails.vue'
import ConsultationsList from '@components/ConsultationsList.vue'
import UserInfo from '@components/UserInfo.vue'
import BaseDialog from '@components/base/BaseDialog.vue'
import RecordConsultation from '@components/RecordConsultation.vue'
import useDeviceAndBrowser from '@/composables/useDeviceAndBrowser'
import useConsultationsWS from '@/composables/useConsultationsWS'
import { useConsultationsStore } from '@/stores/consultations'
import NewFeaturePopup from '@/components/NewFeaturePopup.vue'
import { useNewFeatureDialog } from '@/composables/useNewFeatureDialog'
import { useSystemNote } from '@/composables/useSystemNote'

useConsultationsWS()
const consultationsStore = useConsultationsStore()
const {
  visible: newFeatureDialogVisible,
  markdown: newFeatureDialogContent,
  image: newFeatureDialogImage,
  sendElapsedTime
} = useNewFeatureDialog()

const {
  browserRecommendationPopupVisible,
  hideBrowserRecommendationPopup,
  showBrowserRecommendationPopup
} = useDeviceAndBrowser()

const { isVisible: isSystemNoteVisible, notificationIcon, message, colorClasses } = useSystemNote()

onMounted(showBrowserRecommendationPopup)
</script>