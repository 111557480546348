<template>
  <Listbox v-model="modelControl" :disabled="props.disabled">
    <div class="relative mt-1">
      <ListboxLabel v-if="$slots.label" class="mb-1 flex justify-between gap-3 text-base">
        <slot name="label" />
      </ListboxLabel>

      <ListboxLabel v-else-if="props.label" class="mb-1 block text-base">
        {{ props.label }}
      </ListboxLabel>

      <ListboxButton
        class="relative min-h-[38px] w-full cursor-default rounded-lg border border-accent-500 bg-white bg-white/20 px-4 py-2 pl-3 pr-10 text-left text-accent-500 shadow-md hover:bg-accent-500/30 focus:outline-none focus-visible:border-accent-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-accent-500/30 sm:text-sm lg:min-h-[44px] xl:min-h-[48px]">
        <span class="block truncate">{{ selectedOption?.label || props.placeholder }}</span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
        leave-to-class="opacity-0">
        <ListboxOptions
          class="absolute mt-1 max-h-80 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
          <ListboxOption v-slot="{ active, selected }" v-for="option in props.options || []" :key="option.value"
            :value="option.value" as="template">
            <li :class="[
              active ? 'bg-accent-500/10 text-accent-500' : 'text-gray-900',
              'relative cursor-default select-none px-4 py-2 pl-10'
            ]" :title="option.label">
              <div>
                <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">
                  {{ option.label }}
                </span>
                <span v-if="option.description" class="block text-xs text-gray-500 line-clamp-2 mt-0.5">
                  {{ option.description }}
                </span>
              </div>
              <span v-if="option.value === modelControl"
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-accent-500">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup lang="ts">
import { ENoteType, IOption } from '@/types'
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import { useVModel } from '@vueuse/core'
import { computed } from 'vue'

interface Props {
  modelValue?: string | undefined
  label?: string
  placeholder?: string
  disabled?: boolean
  options?: IOption[]
}
const props = withDefaults(defineProps<Props>(), {
  options: () => [],
  disabled: false,
  label: undefined,
  placeholder: ''
})

const emit = defineEmits<{
  'update:model-value': [ENoteType]
}>()

const modelControl = useVModel(props, 'modelValue', emit, {
  eventName: 'update:model-value',
  passive: true
})

const selectedOption = computed(() =>
  (props.options || []).find((option) => option.value === modelControl.value)
)
</script>