export default {
  locale: {
    notSupported: 'This language is not supported.'
  },
  capacitorAndroid: {
    notification: {
      title: 'PlaynVoice app is recording',
      body: 'Click to open the app and handle the recording process'
    },
    toaster: {
      permissionIsNotGrunted:
        'Permission is not granted. Please provide all needed permissions to start recording',
      stopForegroundServiceError:
        'ERROR with ForegroundService.stopForegroundService, please contact support team'
    }
  },
  generalTips: {
    notFound: 'Not Found',
    loading: 'Loading…',
    offline: 'You are offline, please check your internet connection',
    helpCenter: 'Help Center'
  },
  welcome: {
    greeting: 'Welcome to',
    message:
      'AI-Documentation software for Healthcare Providers. Save Time, Enhance Care & Compliance.',
    reset: {
      title1: 'Reset',
      title2: 'Password'
    },
    signUp: {
      title1: 'Create your',
      title2: 'Account Now'
    }
  },
  formControls: {
    email: {
      label: 'Email',
      placeholder: 'Enter your email'
    },
    password: {
      label: 'Password',
      placeholder: 'Enter your password'
    },
    confirmPassword: {
      label: 'Confirm password',
      placeholder: 'Enter your password again'
    },
    fullName: {
      label: 'Full Name',
      placeholder: 'Enter your full name'
    },
    iamProfessionalHealthProvider: {
      label: 'I am a professional health provider'
    },
    acceptTOS: {
      term: 'I agree to the',
      TC: 'Terms and conditions',
      divider: 'and the',
      DPA: 'Data Processing Agreement'
    }
  },
  signInActions: {
    login: 'Login',
    logout: 'Logout',
    lostPassword: 'Lost your password?',
    continue: 'Continue',
    goToLogin: 'Go to Login',
    tips: {
      emailSent: 'We just sent you an email with a reset link',
      checkEmail: 'If your email is registered, you will receive a password reset link shortly.',
      accountCreatedSuccessfully: 'Account created successfully!',
      resetPasswordFailed: 'Reset password failed.',
      resetPasswordSuccessfully: 'Password has been reset successfully.'
    },
    backToHome: 'Back to the Home Page',
    signUp: 'Sign Up',
    resetPassword: 'Reset Password'
  },
  formValidationErrorMessages: {
    usernamePassword: 'Please fill you username and password.',
    username: 'Please fill you username.',
    fullName: 'Please fill you full name.',
    email: 'Please fill you email.',
    invalidEmail: 'Email is invalid',
    password: 'Please fill you password.',
    confirmPassword: 'Please confirm you password.',
    passwordAndConfirmPasswordNotEqual: 'Passwords are not equal.',
    iamProfessionalHealthProvider: 'You have to be a health provider.',
    acceptTOS: 'Please agree to the TS and DPA.'
  },
  wakeLock: {
    notSupported: 'Wake Lock is not supported by the current browser.'
  },
  dialogs: {
    newFeatureDialog: {
      actions: {
        ok: 'Try it now!',
        cancel: 'Cancel'
      }
    },
    default: {
      actions: {
        ok: 'Ok',
        cancel: 'Cancel'
      }
    },
    deleteConsultation: {
      title: 'Accept to delete the consultation',
      actions: {
        ok: 'Accept'
      }
    },
    micWasLostDialog: {
      title: 'Mic access was lost, end recording to save the data.',
      actions: {
        ok: 'End Consultation',
        cancel: 'Cancel Consultation'
      }
    },
    cancelConsultation: {
      title: 'Do you really want to cancel this ongoing consultation?',
      actions: {
        ok: 'Yes',
        cancel: 'No'
      }
    },
    reloadPage: {
      title: 'New content available',
      content: 'Click on the reload button to update.',
      actions: {
        reload: 'Reload'
      }
    },
    transcriptionsConsultationFeedback: {
      thumbsUpTooltip: 'Useful to me',
      thumbsDownTooltip: 'Not useful to me',
      thumbsUpTitle: 'Please tell us why this note was useful for you',
      thumbsDownTitle: 'Please tell us why this note was not useful for you',
      contentPlaceholder: {
        line1: '- Let us know what you liked and what we could improve.',
        line2:
          '\nWe read every feedback and use it to improve the product. Who knows, we might already have a solution for you! ;) '
      },
      actions: {
        submit: 'Submit'
      },
      toaster: {
        thanksForFeedback: 'Thanks for your feedback',
        canNotBeRetrieved: 'Feedback cannot be retrieved.',
        feedbackNotSent: 'Feedback not sent.'
      }
    },
    useChromeTips: {
      title: 'Please Use Chrome Browser',
      content: 'For the best experience, we suggest using the Chrome browser.',
      actions: {
        understand: 'I understand'
      }
    },
    deviceAndBrowserTips: {
      androidNotChrome: {
        title: 'Useful tips',
        content1: 'We recommend using Chrome browser.',
        content2: "Please don't put the phone in sleep mode as it stops the audio transcription.",
        content3:
          'Place the phone between the patient and you or close by to ensure good audio quality.',
        actions: { ok: 'Ok' }
      },
      androidFirefox: {
        title: 'Attention: Firefox Browser is not supported',
        content1:
          'Firefox can go to standby mode and stop the transcription of your consultation. Please use Chrome.',
        actions: { ok: 'I understand' }
      },
      androidChrome: {
        title: 'Useful tips',
        content1: "Please don't put the phone in sleep mode as it stops the audio transcription.",
        content2:
          'Place the phone between the patient and you or close by to ensure good audio quality.',
        actions: { ok: 'Ok' }
      },
      ios: {
        title: 'Useful tips',
        content1:
          'Place the phone between the patient and you or close by to ensure good audio quality.',
        actions: { ok: 'Ok' }
      },
      mobileFirefox: {
        title: 'Firefox is not supported',
        content: 'Please use Chrome or Safari Browser.',
        actions: { ok: 'Ok' }
      }
    },
    forceEndConsultationDueToDurationLimitation: {
      title: 'We ended your consultation after 3 hours',
      content: {
        line1: 'This is the current maximum duration.',
        line2: 'Your notes are being generated.'
      },
      actions: {
        ok: 'Ok'
      }
    }
  },
  consultationIndexView: {
    waitingScreens: {
      toaster: {
        wsConnectionFailed: 'Failed to connect WebSocket after 15 retries'
      },
      consolidation: {
        title: "Don't close the app!",
        description: 'Processing will start in a few seconds...'
      },
      aiProcessing: {
        title: 'AI magic is happening...',
        description:
          'Your note will be ready in a few minutes. You can start a new consultation now.'
      },
      errorHappened: {
        title: 'An error occurred during processing',
        description: 'PlaynVoice team will fix it. Come back later.',
        description2: 'Thanks for your patience!'
      }
    },
    details: {
      notes: 'Consultation notes',
      noteTypesTitle: {
        standard: 'Standard Notes',
        intake: 'Intake Notes',
        progress: 'Progress Notes',
        genericSummary: 'Generic Summary',
        diagnosticASS: 'Interview ASS',
        collateralInterview: 'Fremdinterview ASS',
        interviewADHS: 'Interview ADHS',
        renewalReport: 'Verlängerungsbericht',
        custom: 'Custom Notes',
        dictation: 'Dictation'
      },
      noteTypesDescription: {
        standard: "Vielseitige Notizvorlage für die tägliche Dokumentation. Therapieunabhängig und ohne Fokus auf Diagnostik oder Verlauf.",
        intake: "Ideal für Erstgespräche, inklusive Anamnese und psychopathologischem Befund.",
        progress: "Zur Dokumentation von Therapieverlauf und relevanten Veränderungen zwischen den Sitzungen.",
        genericSummary: "Eine allgemeine, aber auf den therapeutischen Kontext zugeschnittene Zusammenfassung.",
        diagnosticASS: "Unterstützt die strukturierte Diagnostik von ASS durch gezielte Anamnese und Symptomerhebung. Bericht im Fliesstext.",
        collateralInterview: "Speziell für Fremdanamnesen bei Verdacht auf ASS, beispielsweise durch Eltern oder Bezugspersonen. Bericht im Fliesstext.",
        interviewADHS: "Speziell für die ADHS-Diagnostik, erfasst den Entwicklungsverlauf, zentrale Symptome und deren Auswirkungen im Alltag in einem Bericht.",
        renewalReport: 'Antrag nach der 30. Sitzung. Fasst ein Diktat zum Therapieverlauf zusammen und liefert einen fundierten Bericht für die Fortsetzung der Behandlung im Fliesstext.',
        custom: "Deine eigene Spezialvorlage, um spezifische Dokumentationsbedürfnisse abzudecken.",
        dictation: "Vollständiges Transkript ohne Zusammenfassung – ideal, wenn du deine Gedanken frei formulierst und volle Kontrolle über den Inhalt möchtest."
      },
      save: 'Save',
      copy: 'Copy',
      copied: 'Copied',
      toaster: {
        descriptionEmpty: "Consultation description can't be empty",
        notesUpdated: 'Notes are updated.',
        editNotSaved: 'Edits not saved.',
        noteLengthUpdateError: 'Error with updating note length',
        noteTypeUpdateError: 'Error with updating note type'
      },
      here: 'here',
      lostTimeNotification:
        'Warning: {time} of the consultation was lost or contained only silence. If this wasn’t intentional, click {link} to see possible reasons and how to resolve them.',
      badAudioQualityNotification:
        'Warning: Poor audio quality. This could affect the quality of the generated notes. Please check the positioning of the microphone.',
      wordCountWarningNotification:
        'Warning: The transcript of this session contains fewer than 100 words. Shorter input can lead to inaccurate notes. Please check for accuracy.',
      maxLengthConsultationNotification:
        'Warning: This consultation was automatically terminated after 2 hours. Therefore, this note may not be complete.',
      automaticallyConsolidatedNotification:
        'Warning: The notes were generated on the first {time} of your consultation. Something went wrong after that. Be aware that the notes therefore might not be complete. Read {link} about possible reasons for the loss.'
    },
    recordingProcess: {
      androidTip: "Don't lock your phone",
      initCapture: 'Initializing capture...',
      capturing: 'Capturing...',
      clickTOCapture: 'Click to capture the consultation',
      processingAudio: {
        line1: 'Notes are ready in a few minutes',
        line2: 'You can start a new consultation'
      },
      toaster: {
        capturingComplete: 'Capturing complete',
        capturingCanceled: 'Capturing canceled',
        capturingStarted: 'Capturing started',
        capturingPaused: 'Capturing paused',
        capturingResume: 'Capturing resume',
        micAccessDoesNotProvided: 'Without the microphone permission, no transcription can be done.'
      },
      noteSettings: {
        title: 'Note settings',
        template: 'Template',
        noteLength: 'Note length',
        lengthsTip: {
          short: 'Short notes',
          standard: 'Standard notes',
          long: 'Long notes'
        }
      }
    },
    info: {
      inputDevice: 'Input device'
    },
    actions: {
      newConsultation: 'New Consultation',
      newVisit: 'New Visit',
      notes: 'Notes',
      startConsultation: 'Start Consultation',
      endConsultation: 'End Consultation',
      cancelConsultation: 'Cancel',
      pause: 'Pause',
      resume: 'Resume'
    }
  },
  consultationStoreActions: {
    consultationRemoved: 'The consultation was removed',
    deletingError: 'Error deleting notes.'
  }
}
